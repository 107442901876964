.modal-content {
    -webkit-box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
    -moz-box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 25px 0px rgba(255, 255, 255, 0.1);
}

.modal-content .btn-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill='%23fff' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    max-width: 10px;
    max-height: 10px;
}

.modal-content .modal-body {
    font-size: 16px;
}

.modal {
    /* background-color: rgba(0, 0, 0, 0.5); */

    color: #000;
}

.cc-payment svg {
    display: block;
    max-width: 50px;
    margin: 15px auto;
}

.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 800px) {
    .modal-dialog {
        max-width: 600px !important;
    }
}

.the-nfts .card {
    cursor: pointer;
}

.iframePreloader {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.offcanvas-body {
    padding: 0;
}

.browse-time-left ul {
    padding: 0;
	margin: 0px;
    list-style: none;
	display: flex;
	justify-content: center;
}
.browse-time-left ul > li {
	float: left;
	margin-right: 10px;
	padding-right: 10px;
	font-size: 12px;
	text-transform: uppercase;
	color: #CCC;
}
.browse-time-left ul > li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.browse-time-left ul > li > span {
	font-size: 1.5vw;
	font-weight: 700;
	display: block;
	font-family: "Mulish", sans-serif;
	line-height: normal;
	color: #FFF;
}

.buy-block-container { color: #000;}

.buy-block-header {
    padding: 25px 40px;
    background-color: #8881;
    font-size: 16px;
}

.buy-block-header img {
    border-radius: 10px;
}

.buy-block-header h1 {
    font-size: 1.5rem;
    color: #000;
    text-transform: none;
    font-weight: 500;
}

.buy-block-header h4 {
    font-size: 1rem;
    margin: 0;
}

.buy-block-header hr {
    opacity: 0.6;
    border: 0;
    border-color: #A0AEC0;
    border-style: dashed;
    border-bottom-width: 1px;
    width: 100%;
}

.buy-block-content {
    padding: 20px 40px;
    font-size: 1rem;
}

.buy-block-content h4 {
    margin-bottom: 15px;
}

.col-right-align {
    text-align: right;
}

.col-right-align > * {
    display: inline-block;
}

.connect-button {
    width: 100%;
    padding: 15px 25px;
    background-color: #000;
    color: #FFF;
    border-radius: 30px;
    border: none;
    display: flex;
    justify-content: space-between;
}

.connect-button .title span {
    margin-left: 10px;
    font-weight: 200;
}

.connect-button:hover {
    background-color: #333;
    color: #FFF
}

.connect-button .connect-button-icon {
    width: 25px;
    height: 25px;
}

.connect-button .connect-button-icon img {
    max-height: 25px;
}

.offcanvas-header {
    position: absolute;
    right: 0;
}

@media screen and (max-width: 768px) {
    .browse-time-left ul > li > span {
        font-size: 18px;
    }
}